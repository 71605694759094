import { withPrefix } from "gatsby";
import * as React from "react";
import { Fragment } from "react";
import Helmet from "react-helmet";
import '../assets/scss/Saas.scss';
import {
  Contact,
  Faq, Hero,
  HowWeDo,
  Pricing,
  WhoWeServe
} from '../components';
import Layout from "../components/layout";


// markup
const IndexPage = () => {
  return (

    <Fragment>
      <Layout>
        <Hero />
        <HowWeDo id="features" />
        <WhoWeServe />
        <br />
        <Pricing id="pricing" />
        <br />
        <Faq id="faq" />
        <br />
        <Contact id="contact" />
      </Layout>
      <Helmet>
        <script src={withPrefix('js/intercom.js')} type="text/javascript" />
      </Helmet>
    </Fragment>
  )
}

export default IndexPage
